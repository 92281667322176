import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex: 1;
    width: 100vw;
    padding: 0% 4%;
    flex-direction: column;
    margin-top: ${({ isFromApp }) => isFromApp ? 0 : 50}px;
    text-align: left
`;

export const SectionLabel = styled.b`
    font-size: 26px;
    margin-top: 20px;
    margin-bottom: 10px
`;

export const ContentLabel = styled.span`
    margin: 10px 0px;
    line-height: 18px;
    max-width: 90%
`;
