import React from 'react';

const Home = () => {
    return (
        <div style={{ display: 'flex', flex: 1, height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
            <b>This is the website for Tink</b>
        </div>
    );
}

export default Home;